@import "../../bower_components/bootstrap/bootstrap.min.css";
@import "../../bower_components/fancybox/jquery.fancybox.min.css";
@import "../../bower_components/slickSlider/slick-theme.css";
@import "../../bower_components/slickSlider/slick.css";
@import "../../bower_components/wow/animate.css";
@import "../../bower_components/fontAwesome/fontAwesome.css";
@import "../../bower_components/bootstrap-select/dist/css/bootstrap-select.css";

@import "header";
@import "main";
@import "page";
@import "footer";