/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 1, 2018 */
@font-face {
    font-family: 'clear_sansitalic';
    src: url('../fonts/clearsans-italic-webfont.eot');
    src: url('../fonts/clearsans-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/clearsans-italic-webfont.woff2') format('woff2'),
         url('../fonts/clearsans-italic-webfont.woff') format('woff'),
         url('../fonts/clearsans-italic-webfont.ttf') format('truetype'),
         url('../fonts/clearsans-italic-webfont.svg#clear_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'clear_sans_lightregular';
    src: url('../fonts/clearsans-light-webfont.eot');
    src: url('../fonts/clearsans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/clearsans-light-webfont.woff2') format('woff2'),
         url('../fonts/clearsans-light-webfont.woff') format('woff'),
         url('../fonts/clearsans-light-webfont.ttf') format('truetype'),
         url('../fonts/clearsans-light-webfont.svg#clear_sans_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'clear_sansregular';
    src: url('../fonts/clearsans-regular-webfont.eot');
    src: url('../fonts/clearsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/clearsans-regular-webfont.woff2') format('woff2'),
         url('../fonts/clearsans-regular-webfont.woff') format('woff'),
         url('../fonts/clearsans-regular-webfont.ttf') format('truetype'),
         url('../fonts/clearsans-regular-webfont.svg#clear_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'clear_sansbold';
    src: url('../fonts/clearsans-bold-webfont.eot');
    src: url('../fonts/clearsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/clearsans-bold-webfont.woff2') format('woff2'),
         url('../fonts/clearsans-bold-webfont.woff') format('woff'),
         url('../fonts/clearsans-bold-webfont.ttf') format('truetype'),
         url('../fonts/clearsans-bold-webfont.svg#clear_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'clear_sansregular';
$bold: 'clear_sansbold';
$light: 'clear_sans_lightregular';
$italic: 'clear_sansitalic';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	color: #464646;
	margin: 0;
	@media screen and (max-width: 1170px) {
		padding-top: 98px;
	}
	@media screen and (max-width: 991px) {
		font-size: 16px;
	}
	@media screen and (max-width: 480px) {
		padding-top: 96px;
	}
}

h1 {
	display: block;
	font-family: $bold;
	font-size: 50px;
	text-transform: uppercase;
	color: #15193d;
	text-align: center;
	margin: 0 0 35px 0;
	line-height: 1;
	text-align: left;
	@media screen and (max-width: 991px) {
		margin-bottom: 25px;
		font-size: 32px;
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
}
h2 {
	display: block;
	font-family: $bold;
	font-size: 36px;
	color: #15193d;
	margin-top: 0px;
	margin-bottom: 35px;
	text-align: center;
	a {
		font-size: 36px!important;
		font-family: $bold;
		color: #15193d;
		display: block;
	}
	@media screen and (max-width: 991px) {
		font-size: 30px;
		margin-bottom: 25px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-family: $bold;
	font-size: 22px;
	color: #15193d;
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
}
h4 {
	font-family: $bold;
	font-size: 20px;
	text-transform: uppercase;
	color: #15193d;
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #15193d;
	text-align: center;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #15193d;
}
a {
	color: #464646;
	transition: 350ms;
	&:focus {
		color: #464646;
		text-decoration: none;
	}
	&:hover {
		color: #d9a100;
	}
}
input, textarea, button {
	border-radius: 0px;
	-webkit-appearance: none;
}
*:after, *:before {
	line-height: 1;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 20px;
	textarea, input {
		font-family: $regular;
		font-size: 16px;
		background-color: #fff;
		padding: 5px 15px;
	}
	a,p {
		font-size: 20px;
		text-decoration: none;
	}
	@media screen and (max-width: 991px) {
		font-size: 16px;
		a, p {
			font-size: 16px;
		}
	}
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}
.btn {
	display: table;
	width: auto!important;
	background-color: #d9a100!important;
	font-family: $bold!important;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #d9a100!important;
	padding: 8px 40px!important;
	transition: 350ms;
	&:focus, &:active {
		color: #fff!important;
		background-color: #d9a100!important;
	}
	&:hover, &.active {
		background-color: #15193d!important;
		border: 2px solid #15193d!important;
		color: #fff!important;
	}	
}

.check--list {
	@include ul-default;
	overflow: hidden;
	margin: 10px 0px;
	li {
		display: block;
		padding-left: 25px;
		position: relative;
		margin-bottom: 5px;
		font-size: 20px;
		&:before {
			content: '\f00c';
			display: block;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #d9a100;
			position: absolute;
			left: 0;
			top: 3px;
			line-height: 1;
		}
	}
	@media screen and (max-width: 991px) {
		li {
			font-size: 16px;
			&:before {
				font-size: 16px;
			}
		}
	}
}
.check--list--disc {
	@include ul-default;
	li {
		display: block;
		padding-left: 40px;
		position: relative;
		margin-bottom: 5px;
		font-size: 20px;
		&:before {
			content: '\f058';
			display: block;
			font-family: 'fontAwesome';
			font-size: 28px;
			color: #fff;
			position: absolute;
			left: 0;
			top: 0px;
			line-height: 1;
		}
	}
	@media screen and (max-width: 991px) {
		li {
			font-size: 16px;
			padding-left: 30px;
			&:before {
				top: 0;
				font-size: 20px;
			}
		}
	}
}

/* header */
header {
	position: relative;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
	.up {
		background-color: #d9a100;
		color: #fff;
		padding: 4px 0px;
		text-align: center;
		a {
			color: #fff;
			text-decoration: underline;
		}
		/* header delivery */
		.delivery {
			position: relative;
			font-family: $bold;
			font-size: 18px;
			float: left;
			line-height: 1;
			padding-top: 2px;
			margin-left: 40px;
			@include inline-block;
			&:after {
				content: '';
				width: 30px;
				height: 30px;
				background-image: url(../img/logistics-delivery-truck-in-movement.svg);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				left: -40px;
				top: -4px;
			}
		}
		/* header delivery */

		/* header email */
		.email {
			padding-left: 25px;
			position: relative;
			font-size: 18px;
			@include inline-block;
			&:after {
				content: '\f0e0';
				display: block;
				font-family: 'fontAwesome';
				font-size: 18px;
				line-height: 1;
				position: absolute;
				left: 0;
				top: 3px;
			}
			&:hover {
				text-decoration: none;
			}
		}
		/* header email */

		/* header callback btn */
		.callback--btn {
			padding-left: 25px;
			position: relative;
			float: right;
			font-size: 18px;
			@include inline-block;
			&:after {
				content: '\f095';;
				font-family: 'fontAwesome';
				font-size: 22px;
				line-height: 1;
				position: absolute;
				top: 1px;
				left: 0;
			}
			&:hover {
				text-decoration: none;
			}
		}
		/* header callback btn */

	}
	
	.middle {
        min-height: 140px;
		background-color: #15193d;
		padding: 15px 0px;
		color: #fff;
		.container {
			padding-left: 350px;
			position: relative;
		}
		
		/* header logo */
		.logo {
			display: block;
            width: 300px;
			position: absolute;
			top: 0;
			left: 15px;
            img {
                width: 100%;
            }
		}
		/* header logo */

		/* header select city */
		.select--city {
			@include inline-block;
			.bootstrap-select {
				width: auto!important;
				min-width: 140px;
			}
			.btn {
				padding: 0px!important;
				background-color: transparent!important;
				border: none!important;
				border-bottom: 1px dashed #d9a100!important;
				font-size: 20px;
				outline: none!important;
				color: #fff!important;
				box-shadow: none;
				.caret {
					width: 14px;
					height: 14px;
					border: none;
					right: -30px;
					top: 8px;
					transition: 400ms;
					&:after {
						content: '\f078';
						display: block;
						font-family: 'fontAwesome';
						font-size: 14px;
						color: #d09a08;
					}
				}
				&:hover, &:focus {
					color: #fff!important;
				}
			}
			.open {
				.btn .caret {
					top: 10px;
					transform: rotate(-180deg);
				}
			}
			.dropdown-menu {
				min-width: 220px;
				top: 130%;
				border-radius: 0px;
				overflow: visible!important;
				padding: 8px 0;
				&:after {
					content: '';
					display: block;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-bottom: 8px solid #fff;
					position: absolute;
					top: -8px;
					left: 20px;
				}
				li {
					a {
						display: block;
						position: relative;
						font-family: $bold;
						font-size: 18px;
						color: #15193d;
						padding: 8px 5px 8px 30px;
						line-height: 1;
						outline: none;
						white-space: nowrap;
						&:after {
							content: '\f101';
							display: table;
							font-family: 'fontAwesome';
							font-size: 18px;
							position: absolute;
							top: 8px;
							left: 10px;
							color: #d9a100;
						}
						&:hover {
							background-color: #e1e1e1;
						}
					}
					&.selected {
						a {
							background-color: #e1e1e1;
						}
					}
				}
			}
		}
		/* header select city */

		/* header phones */
		.phones {
			position: relative;
			padding-left: 30px;
            float: right;
			margin: 2px 0px 0px 10px;
			@include inline-block;
			&:after {
				content: '\f095';;
				font-family: 'fontAwesome';
				font-size: 24px;
				color: #d09a08;
				line-height: 1;
				position: absolute;
				top: 0;
				left: 0;
			}
			ul {
				@include ul-default;
				li a {
					display: block;
					font-size: 20px;
					color: #fff;
					text-decoration: none;
					span {
						color: #d09a08;
					}
				}
			}
		}
		/* header phones */	

		/* header nav */
		.navbar {
			width: calc(100% - 20px);
			min-height: 1px;
			border: none;
			margin: 15px 0 0 -25px;
			text-align: justify;
			text-align-last: justify;
			@include inline-block;
			.navbar-nav {
				float: none;
				> li {
                    position: relative;
					margin: 10px 3px 0px 3px;
					float: none;
					@include inline-block;
					> a {
						display: block;
						font-size: 18px;
						color: #fff;
						padding: 0px;
						border-bottom: 1px solid transparent;
						&:focus {
							background-color: transparent;
						}
						&:hover {
							background-color: transparent;
							border-bottom: 1px solid #fff;
						}
					}
					&.active > a {
						border-bottom: 1px solid #fff;
					}
                    &.submenu {
                        > ul {
                            display: none;
                            @include ul-default;
                            background-color: #fff;
                            text-align: left;
                            text-align-last: left;
                            position: absolute;
                            left: 0;
                            top: 100%;
                            padding: 10px 0;
                            z-index: 1;
                            box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
                            li {
                                a {
                                    display: block;
                                    padding: 5px 15px;
                                    font-size: 16px;
                                    white-space: nowrap;
                                    line-height: 1;
                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                                &.active {
                                    a {
                                        color: #d9a100;
                                    }
                                }
                            }
                        }
                    }
				}
			}
		}
		/* header nav */

		/* header search */
		.search--btn {
			float: right;
			position: relative;
			margin-top: 26px;
			color: #d09a08;
			@include inline-block;
			&:after {
				content: '\f002';
				display: block;
				font-family: 'fontAwesome';
				font-size: 18px;
			}
			&:hover {
				color: #fff;
				text-decoration: none;
			}
		}	
		.search {
			display: none;
			width: 300px;
			position: absolute;
			top: 77px;
			right: 15px;
			background-color: #fff;
			border: 1px solid #15193d;
			box-sizing: border-box;
			form {
				position: relative;
				display: block;
				padding-right: 20px;
				input {
					border: none;
					outline: none;
					padding: 5px 10px;
				}
				.search--close {
					display: table;
					position: absolute;
					right: 5px;
					top: 5px;
					&:after {
						content: '\f00d';
						display: block;
						font-family: 'fontAwesome';
						font-size: 16px;
						color: #d09a08;
					}
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
		/* header search */
	}

	/* header nav cat */
	.navbar--cat {
		min-height: 1px;
		margin: 0;
		border: none;
		.navbar-nav {
			text-align: justify;
			text-align-last: justify;
			float: none;
			> li {
				float: none;
				text-align: left;
				text-align-last: left;
				@include inline-block;
				> a {
					position: relative;
					padding: 8px 5px 8px 5px;
					background-color: transparent;
					font-family: $bold;
					font-size: 16px;
					color: #15193d;
					line-height: 1;
					text-align: center;
					text-align-last: center;
					&:focus {
						background-color: transparent;
					}
					&:hover {
						background-color: transparent;
						text-decoration: underline;
					}
					/* &:after {
						content: '';
						display: block;
						width: 8px;
						height: 8px;
						background-color: #d9a100;
						position: absolute;
						left: 5px;
						top: 11px;
					} */
				}
				&.active > a {
					text-decoration: underline;
				}
			}
		}
	}
	/* header nav cat */

	@media screen and (max-width: 1199px) {
		.middle {
			.container {
				padding-left: 230px;
			}
            .logo {
                width: 200px;
            }
			.navbar {
				.navbar-nav {
					> li {
						margin: 10px 5px;
					}
				}
			}
		}
	}
	
	@media screen and (max-width: 1170px) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9;
		.up {
			.delivery {
				font-size: 16px;
			}
			.email {
				font-size: 16px;
			}
			.callback--btn {
				font-size: 16px;
				margin-right: 50px;
			}
		}
		.middle {
            min-height: 0px;
			padding: 10px 0px;
			.container {
				padding-left: 15px;
			}
			.logo {
				display: block;
				width: 130px;
				position: relative;
				left: 0;
				margin: 0 auto;
				img {
					display: block;
					width: 100%;
				}
			}
			.navbar-toggle {
				right: 15px;
				top: -41px;
				span {
					background-color: #fff;
				}
			}
			.navbar {
				display: none;
				width: 100%;
				min-height: 0px;
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #15193d;
                text-align-last: center;
                text-align: center;
				margin: 0;
				z-index: 1;
				.navbar-collapse {
					display: table;
					height: 100%!important;
					max-height: 100%;
					width: 100%;
				}
				.navbar-nav {
					display: table-cell;
					vertical-align: middle;
					margin: 0;
					> li {
						display: table;
						margin: 10px auto;
						> a {
                            display: table;
							text-align: center;
	                        margin: 0 auto;
    					}
                        &.submenu > ul {
                            position: relative;
                            top: 0;
                            left: 0;
                            margin-top: 10px;
                            background-color: transparent;
                            box-shadow: none;
                            text-align: center;
                            text-align-last: center;
                            li {
                                a {
                                    white-space: initial;
                                    color: #fff;
                                }
                            }
                        }
					}
				}
			}
			.navbar--close {
				position: absolute;
				top: 10px;
				right: 10px;
				width: 30px;
				height: 30px;
				outline: none;
				&:after, &:before {
					content: '';
					display: block;
					width: 80%;
					height: 3px;
					background-color: #fff;
					position: absolute;
					left: 0;
					right: 0;
					top: 14px;
					margin: 0 auto;
				}
				&:before {
					transform: rotate(45deg);
				}
				&:after {
					transform: rotate(-45deg);
				}
			}
			.phones {
				position: absolute;
				right: 45px;
				top: 12px;
				margin: 0;
				ul li a {
					font-size: 18px;
				}
			}
			.select--city {
				position: absolute;
				top: 7px;
				left: 65px;
				margin: 0;
				.btn {
					font-size: 18px!important;
					.caret {
						right: -20px;
					}
				}
				.dropdown-menu li a {
					font-size: 16px;
				}
			}
			.search--btn {
				position: absolute;
				top: 15px;
				right: 15px;
				margin: 0;
			}
			.search {
				top: 58px;
				z-index: 1;
			}
		}
		.down {
			background-color: #15193d;
			.navbar {
				.navbar-toggle {
					top: -51px;
					span {
						background-color: #d09a08;
					}
				}
				.navbar-nav {
					> li {
						> a {
							color: #fff;
						}
					}
				}
			}
		}
		.navbar-toggle {
			width: 35px;
			height: 30px;
			position: absolute;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #3361eb;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.up {
			.delivery {
				display: none;
			}
			.email {
				margin-left: 3px;
				float: left;
			}
		}
		.down {
			.navbar {
				.navbar-nav {
					text-align: left;
					text-align-last: left;
					> li {
						display: block;
						a {
							br {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.up {
			.callback--btn {
				margin-right: 0;
			}
		}
		.middle {
			.navbar-toggle {
				top: 6px;
			    right: auto;
			    left: 15px;
			}
			.phones {
				display: none;
				position: fixed;
				left: 0;
				top: 0;
				right: 0;
				padding: 10px;
				background-color: rgba(21, 25, 61, 0.9);
				text-align: center;
				z-index: 1;
				&:after {
					display: none;
				}
			}
			.phones--btn {
				display: block;
				position: absolute;
				font-size: 22px;
				color: #d9a100;
				position: absolute;
				right: 48px;
				top: 10px;
				&:hover {
					text-decoration: none;
				}
				&:after {
					content: '\f095';
					font-family: 'fontAwesome';
				}
			}
			.search {
				width: 100%;
				left: 0;
				right: 0;
			}
		}
		.navbar--cat {
			display: none;
		}
	}
	@media screen and (max-width: 480px) {
		.up {
			.email {
				width: 20px;
				height: 20px;
				overflow: hidden;
				&:after {
					top: 1px;
				}
			}
			.callback--btn {
				width: 22px;
				height: 20px;
				overflow: hidden;
				color: transparent;
				&:after {
					color: transparent;
					background: url(../img/incoming-call.svg) no-repeat center center;
					background-size: contain;
					width: 20px;
					height: 18px;
				}
			}
		}
		.middle {
			.select--city {
				position: absolute;
				top: -36px;
				left: 50px;
				.btn {
					font-family: $regular!important;
					font-size: 16px!important;
					border-bottom: 1px dashed #fff!important;
					line-height: 1;
					.caret {
						right: -15px;
						top: 4px;
						&:after {
							font-size: 12px;
							color: #fff;
						}
					}
				}
				.open .btn .caret {
					top: 4px;
				}
				.dropdown-menu {
					li a {
						font-size: 14px;
						&:after {
							top: 6px;
						}
					}
				}
			}
		}
	}
}
/* header */