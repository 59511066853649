/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	position: relative;
	overflow: hidden;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
		.slider__block {
			width: 950px;
			background-color: rgba(21, 25, 61, 0.7);
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			padding: 35px 35px 75px;
			margin: 0 auto;
			margin-top: -105px;
			text-align: center;
			color: #fff;
			.slider__block__title {
				display: block;
				font-family: $bold;
				font-size: 36px;
				span {
					display: block;
					font-family: $regular;
				}
			}
		}
	}
	.slick-dots {
		bottom: 50%;
		margin-bottom: -75px;
		li {
			margin: 0px 2px;
			button {
				background-color: #d9a100;
				width: 15px;
				height: 15px;
				border-radius: 100%;
				border: 4px solid #d9a100;
				box-sizing: border-box;
				transition: 250ms;
				&:before {
					display: none;
				}
				&:hover {
					border: 2px solid transparent;
				}
			}
			&.slick-active {
				button {
					border: 2px solid transparent;
				}
			}
		}
	}
	&:before {
		content: '';
		height: 150px;
		width: 800px;
		background-color: rgba(217, 161, 0, 0.7);
		position: absolute;
	    bottom: -7px;
   		left: -100px;;
		z-index: 1;
		transform: rotate(30deg);
	}
	&:after {
		content: '';
		height: 150px;
		width: 500px;
		background-color: rgba(21, 25, 61, 0.7);
		position: absolute;
		bottom: -100px;
		left: -90px;
		z-index: 1;
		transform: rotate(30deg);
	}
	@media screen and (max-width: 1440px) {
		&:before {
			height: 100px;
			bottom: -65px;
		}
		&:after {
			height: 100px;
		}
	}
	@media screen and (max-width: 1280px) {
		&:before {
			height: 75px;
			bottom: -95px;
		}
		&:after {
			height: 75px;
		}
	}
	@media screen and (max-width: 1170px) {
		.slick-slide {
			.slider__block {
				.slider__block__title {
					font-size: 30px;
				}
			}
		}
		&:after, &:before {
			display: none;
		}
	}
	@media screen and (max-width: 991px) {
		.slick-slide {
			.slider__block {
				width: 96%;
				padding: 15px;
				top: 20px;
				margin: 0 auto;
				.slider__block__title {
					font-size: 26px;
				}
			}
		}
		.slick-dots {
			margin-bottom: -50px;
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			.slider__block {
				.slider__block__title {
					font-size: 22px;
				}
			}
		}
		.slick-dots {
			bottom: 10px;
			margin-bottom: 0;
		}
	}
	@media screen and (max-width: 680px) {
		.slick-slide {
			img {
				height: 250px;
				width: auto;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			.slider__block {
				padding: 10px 10px;
			}
		}
	}
}
/* main slider */

/* main production */
.cat {
	.cat__col {
		display: block;
		position: relative;
		color: #fff;
		margin-bottom: 30px;
		text-decoration: none!important;
		.cat__col__img {
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				transition: 350ms;
			}
		}
		.cat__col__title {
			display: block;
			width: 50%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(21, 25, 61, 0.7);
			font-family: $bold;
			font-size: 20px;
			text-transform: uppercase;
			padding: 0 25px;
			box-sizing: border-box;
			table {
				height: 100%;
				td {
					vertical-align: middle;
					span {
						display: block;
						position: absolute;
						text-align: left;
						font-family: $bold;
						color: #d9a100;
						text-transform: none;
						text-decoration: none;
						bottom: 20px;
						left: 25px;
					}
				}
			}
		}
		&:hover {
			color: #d9a100;
			text-decoration: none!important;
			.cat__col__img {
				img {
					transform: scale(1.05);
				}
			}
			.cat__col__title {
				text-decoration: underline;
				table td span {
					text-decoration: none;
				}
			}
		}
		&:focus {
			text-decoration: none!important;
		}
		&.col--full {
			.cat__col__img {
				width: 50%;
			}
			.cat__col__title {
				background-color: rgba(21, 25, 61, 1);
			}
		}
	}
	@media screen and (max-width: 991px) {
		.cat__col .cat__col__title {
			font-size: 18px;
		}	
	}
	@media screen and (max-width: 767px) {
		.cat__col {
			.cat__col__title {
				position: relative;
				width: 100%;
				display: block;
				font-size: 16px;
				padding: 5px;
				background-color: rgba(21, 25, 61, 1);
				table td span {
					position: relative;
					bottom: 0;
					left: 0;
					margin: 5px 0px;
				}
			}	
			&.col--full {
				.cat__col__img {
					width: 100%;
				}
			}
		}
	}
}
/* main production */

/* main benefits */
.benefits {
	background: url(../img/img-11.jpg) no-repeat;
	background-size: cover;
	color: #fff;
	h2 {
		color: #fff;
	}
	.benefits__col {
		.check--list--disc {
			li:before {
				top: 2px;
			}
		}
		&.left {
			text-align: right;
			.check--list--disc {
				li {
					padding-left: 0;
					padding-right: 40px;
					&:before {
						left: auto;
						right: 0;
					}
				}
			}
		}
		&.center {
			text-align: center;
			img {
				display: block;
				margin: 0 auto 35px auto;
			}
			.check--list--disc {
				li {
					padding: 40px 0 0 0;
					&:before {
						top: 5px;
						left: 0;
						right: 0;
						margin: 0 auto;
					}
				}
			}
		}
		&.right {
			text-align: left;
		}
	}
	@media screen and (max-width: 991px) {
		.benefits__col {
			&.left {
				text-align: left;
				.check--list--disc {
					li {
						padding: 0px 0 0 30px;
						&:before {
							top: 0px;
							left: 0;
							right: 0;
							margin: 0;
						}
					}
				}
			}
			&.center {
				text-align: left;
				img {
					margin: 10px auto;
				}
				.check--list--disc {
					li {
						padding: 0px 0 0 30px;
						&:before {
							top: 0px;
							left: 0;
							right: 0;
							margin: 0;
						}
					}
				}
			}
			&.right {
				text-align: left;
			}
			.check--list--disc {
				li:before {
					top: 0px;
				}
			}
		}
	}
}
/* main benefits */

/* main about */
.about {
	.check--list {
		margin-bottom: 25px;
	}
	img {
		display: block;
		width: 100%;
	}
} 
/* main about */ 

/* main partners */
.partners {
	background-color: #f0f0f0;
	.partners__block {
		padding-bottom: 25px;
		margin-bottom: 50px;
		border-bottom: 1px solid #d9a100;
		.partners__block__info {
			position: relative;
			padding-left: 200px;
			margin-bottom: 25px;
			.partners__block__info__img {
				width: 180px;
				position: absolute;
				top: 6px;
				left: 0;
				img {
					display: block;
					width: 100%;
				}
			}
		}
	}
	.partners__slider {
		margin: 0 -15px;
		.slick-slide {
			outline: none;
			margin: 0 15px;
			img {
				display: block;
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.partners__block {
			padding-bottom: 25px;
			margin-bottom: 25px;
			.partners__block__info {
				padding-left: 100px;
				margin-bottom: 15px;
				.partners__block__info__img {
					width: 90px;
				}
			}
		}
	}
}
/* main partners */

/* main certificates */
.certificates {
	.certificates__slider {
		margin: 0 -5px;
		.slick-slide {
			outline: none;
			margin: 0 15px;
			img {
				display: block;
				width: 100%;
			}
		}
		.slick-arrow {
			display: block;
			width: 26px;
			height: 26px;
			padding: 0px;
			border-radius: 100%;
			background-color: #15193d;
			overflow: hidden;
			transition: 350ms;
			line-height: 1;
			z-index: 1;
			&:before {
				display: none;
			}
			&:after {
				display: block;
				font-family: 'fontAwesome';
				font-size: 14px;
				color: #fff;
				text-align: center;
			}
			&.slick-prev {
				&:after {
					content: '\f053';
					margin-left: -2px;
				}
			}
			&.slick-next {
				&:after {
					content: '\f054';
					margin-right: -2px;
				}
			}
			&:hover {
				background-color: #d9a100;
			}
		}
	}
	@media screen and (max-width: 1170px) {
		.certificates__slider {
			padding: 0 35px;
			.slick-arrow {
				&.slick-prev {
					left: 10px;
				}
				&.slick-next {
					right: 10px;
				}
			}
		}
	}
}
/* main certificates */

/* main news */
.news {
	.news__col {
		display: block;
		position: relative;
		text-align: left;
		color: #fff;
		.news__col__img {
			overflow: hidden;
			position: relative;
			img {
				display: block;
				width: 100%;
			}
			.news--date {
				display: block;
				position: absolute;
				background-color: rgba(21,25,61,0.7);
				color: #d9a100;
				position: absolute;
				padding: 15px 30px 15px 60px;
				left: 0;
				right: 0;
				bottom: 0;
				&:after {
					content: '\f017';
					font-family: 'fontAwesome';
					font-size: 22px;
					position: absolute;
					left: 30px;
					top: 17px;
					color: #d9a100;
				}
			}
		}
		.news__col__block {
			padding: 20px 30px;
			background-color: rgba(21,25,61,0.7);
			h4 {
				display: block;
				min-height: 45px;
				text-align: left;
				color: #fff;
				transition: 350ms;
			}
		}
		&:hover {
			.news__col__block {
				h4 {
					color: #d9a100;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.news__col .news__col__img .news--date:after {
			top: 14px;
		}
	}
	@media screen and (max-width: 767px) {
		.news__col {
			margin-bottom: 25px;
			.news__col__img {
				.news--date {
					padding: 12px 15px 12px 40px;
					&:after {
						left: 15px;
						top: 12px;
					}
				}
			}
			.news__col__block {
				padding: 10px 15px;
				background-color: rgba(21,25,61, 1);
				h4 {
					min-height: 1px;
				}
			}
		}
	}
}
/* main news */

/* main download */
.download--block {
	position: fixed;
	right: 0;
	top: 35%;
	z-index: 1;
	a {
		display: block;
		width: 85px;
		position: relative;
		background-color: #15193d;
		background-position: center top 8px;
		background-repeat: no-repeat;
		background-size: 30px;
		padding: 45px 15px 15px;
		font-size: 15px;
		text-align: center;
		color: #fff!important;
		margin-bottom: 10px;
		line-height: 1;
		text-decoration: none!important;
		&:hover {
			background-color: rgba(21,25,61,0.8);
			color: #fff;
		}
		&.download--block--price {
			background-image: url(../img/download.svg);
		}
		&.download--block--cat {
			background-image: url(../img/1download.svg);
			
		}
	}
	@media screen and (max-width: 767px) {
		top: auto;
		bottom: 70px;
		a {
			width: 40px;
			padding: 30px 5px 5px;
			font-size: 9px;
			background-size: 20px;
			background-position: center top 5px;
		}
	}
}
/* main download */

.arrow--up {
	display: block;
	width: 35px;
	height: 35px;
	position: fixed;
	right: 20px;
	bottom: 25px;
	font-size: 18px!important;
	color: #d9a100;
	background-color: #15193d;
	text-align: center;
	border-radius: 100%;
	opacity: 0;
	z-index: 1;
	&:focus {
		color: #d9a100;
	}
	&:hover {
		opacity: 0.8;
	}
	&:after {
		content: '\f077';
		display: block;
		font-family: 'fontAwesome';
		font-size: 22px;
		position: absolute;
		right: 0;
		left: 0;
		top: 3px;
	}
	@media screen and (max-width: 1170px) {
		display: none;
	}

}

/* main content */