/* page content */
.page {
	padding-bottom: 35px;
	.news1, .news {
		.date {
			color: #d9a100;
		}
		a {
			color: #eba300;
			text-decoration: underline;
			&:hover {
				color: #eba300;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #eba300;
		}
	}
	.partners {
		background-color: #fff;
	}
	h1 {
		font-size: 35px;
		font-family: $regular;
	}
	a {
		color: #464646;
		text-decoration: underline;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #d9a100;
			text-decoration: none;
		}
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 15px;
		h1 {
			font-size: 32px;
		}
	}
	@media screen and (max-width: 767px) {
			h1 {
				font-size: 28px;
			}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
		.about--img--right {
			display: none!important;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 18px;
	margin: 15px 0px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #15193d;
			@include inline-block;
			a {
				font-size: 18px;
				text-decoration: none;
				color: #8b8b8b;
				&:hover {
					color: #15193d;
				}
			}
			&:after {
				content: '/';
				margin: 3px 4px 0px 7px;
				color: #8b8b8b;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
	@media screen and (max-width: 991px) {
		margin-top: 10px;
		font-size: 16px;
		ul li a {
			font-size: 16px;
		}
	}
	@media screen and (max-width: 767px) {
		font-size: 14px;
		ul li {
			a {
				font-size: 14px;
			}
			&:after {
				margin: 3px 2px 0px 3px;
			}
		}
	}
}
/* breadcrumbs */

/* page about */
.about--page {
	padding-bottom: 0px;
	> .container {
		position: relative;
	}
	.about--img--left {
		margin: 0 25px 0px -400px;
		float: left;
	}
	.about--img--right {
		position: absolute;
		right: -320px;
		top: 0;
	}
	.about--img {
		font-size: 0;
		margin-top: 25px;
		.about--img__col {
			width: 25%;
			overflow: hidden;
			@include inline-block;
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
	@media screen and (max-width: 1680px) {
		.about--img--right  {
			display: none;
			img {
				display: block;
				width: 100%;
			}
		}
	}
}
/* page about */

/* page catalog */

/* page sidebar */
.sidebar {
	.sidebar__btn {
		display: none;
	}

	ul {
		@include ul-default;
		li {
			a {
				text-decoration: none;
			}
		}
	}
	> ul {
		> li {
			> a {
				display: block;
				font-family: $bold;
				padding: 10px 0px;
				line-height: 1;
			}
			&.active > a {
				color: #d9a100;
			}
			ul li {
				a {
					display: block;
					position: relative;
					line-height: 1;
					padding: 5px 5px 5px 20px;
					font-size: 16px;
					&:after {
						content: '\f101';
						display: block;
						font-family: 'fontAwesome';
						color: #d9a100;
						position: absolute;
						left: 0;
						top: 4px;
					}
				}
				&.active a {
					color: #d9a100;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		max-height: 42px;
		margin-bottom: 25px;
		overflow: hidden;
		transition: 500ms;
		&.open {
			max-height: 1500px;
		}
		.sidebar__btn {
			display: block;
			width: 100%;
			position: relative;
			font-family: $bold;
			font-size: 16px;
			background-color: #15193d;
			text-decoration: none;
			text-transform: uppercase;
			padding: 10px;
			color: #fff;
			&:after, &:before {
				content: '';
				display: block;
				position: absolute;
				width: 15px;
				height: 2px;
				background-color: #fff;
				line-height: 1;
				top: 50%;
				right: 15px;
				transition: 350ms;
			}
			&:before {
				transform: rotate(90deg);
			}
			&:hover {
				color: #fff;
			}
			&.active {
				&:before {
					opacity: 0;
				}
			}
		}
	}
}
/* page sidebar */

/* page cat inside */
.cat--inside {
	.cat--inside__col {
		display: block;
		text-decoration: none;
		margin-bottom: 25px;
		.cat--inside__col__img {
			max-height: 160px;
			overflow: hidden;
			img {
				display: block;
				width: 100%;
			}
		}
		h4 {
			text-transform: inherit;
			transition: 350ms;
		}
		p {
			font-size: 17px;
			line-height: 1.2;
		}
		&:hover {
			color: #464646;
			h4 {
				color: #d9a100;
			}
		}
	}
	@media screen and (max-width: 1170px) {
		.cat--inside__col .cat--inside__col__img {
			max-height: 115px;
		}
	}
	@media screen and (max-width: 767px) {
		.cat--inside__col .cat--inside__col__img {
			max-height: 100%;
		}
	}
}
/* page cat inside */

/* page pagination */
.pagination {
	ul {
		@include ul-default;
		li {
			margin-bottom: 10px;
			@include inline-block;
			a {
				text-decoration: none;
				width: 30px;
				height: 30px;
				display: block;
				border: 2px solid transparent;
				font-size: 16px;
				text-align: center;
				box-sizing: border-box;
				padding: 5px 2px 0px;
				outline: none;
				color: #15193d;
				line-height: 1;
				&:hover {
					border: 2px solid #d9a100;
					color: #15193d;
				}
			}
			&.active {
				a {
					border: 2px solid #d9a100;
				}
			}
			&.prev, &.next {
				a {
					position: relative;
					&:hover {
						border: 2px solid transparent;
					}
					&:after {
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-top: 5px solid transparent;
						border-bottom: 5px solid transparent;
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						margin: -5px auto;
						transition: 350ms;
					}
				}
			}	
			&.prev a {
				&:after {
					border-right: 7px solid #15193d;
				} 
				&:hover:after {
					border-right: 7px solid #15193d;
				}
			}
			&.next a {
				&:after {
					border-left: 7px solid #15193d;
				} 
				&:hover:after {
					border-left: 7px solid #15193d;
				}
			}
		}
	}
}
/* page pagination */

/* page catalog */

/* page good */
.slider--for {
	position: relative;
	&:after {
		content: '\f00e';
		font-family: 'fontAwesome';
		font-size: 25px;
		color: #fff;
		position: absolute;
		right: 20px;
		bottom: 20px;
	}
	.slick-slide {
		outline: none;
		height: 300px;
		overflow: hidden;
		img {
			display: block;
			width: 100%;
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			height: 250px;
		}
	}
	@media screen and (max-width: 420px) {
		.slick-slide {
			height: 200px;
		}
	}
}

.slider--nav {
	margin: 20px -10px 40px;
	.slick-slide {
		height: 90px;
		margin: 0 10px;
		position: relative;
		outline: none;
		cursor: pointer;
		overflow: hidden;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(21, 25, 61, 0.7);
			opacity: 0;
			transition: 350ms;
		}
		&:hover {
			&:after {
				opacity: 1;
			}
		}
		&.slick-current:after {
			opacity: 1;
		}
		img {
			display: block;
			width: 100%;
		}
	}
	.slick-arrow {
		display: block;
		width: 30px;
		height: 30px;
		padding: 0px;
		border-radius: 100%;
		background-color: #15193d;
		overflow: hidden;
		transition: 350ms;
		line-height: 1;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			display: block;
			font-family: 'fontAwesome';
			font-size: 12px;
			color: #fff;
			text-align: center;
		}
		&.slick-prev {
			left: -7px;
			&:after {
				content: '\f053';
				margin-left: -2px;
			}
		}
		&.slick-next {
			right: -7px;
			&:after {
				content: '\f054';
				margin-right: -2px;
			}
		}
		&:hover {
			background-color: #d9a100;
		}
	}
	@media screen and (max-width: 767px) {
		padding: 0px 25px;
		.slick-arrow {
			&.slick-prev {
				left: 0px;
			}
			&.slick-next {
				right: 0;
			}
		}
	}
	@media screen and (max-width: 420px) {
		.slick-slide {
			height: 60px;
		}
	}
	@media screen and (max-width: 380px) {
		.slick-slide {
			height: 50px;
		}
	}
	@media screen and (max-width: 320px) {
		.slick-slide {
			height: 40px;
		}
	}
}
/* page good */

/* page contacts */
.map {
	min-height: 400px;
	margin-bottom: 40px;
	@media screen and (max-width: 767px) {
		min-height: 250px;
		margin-bottom: 15px;
		> ymaps {
			height: 250px!important;
		}
	}
}

.contacts--left {
	@include ul-default;
	li {
		margin-bottom: 20px;
		h4 {
			color: #464646;
		}
		a {
			display: block;
			text-decoration: none;
		}
	}
}
.form {
	display: block;
	width: 100%;
	float: left;
	input, textarea {
		width: 100%;
		border: 1px solid #b5b5b5;
		margin-bottom: 15px;
	}
	.btn {
		float: right;
	}
	@media screen and (max-width: 991px) {
		#RecaptchaField2 {
			width: 100%;
			margin-bottom: 15px;
			transform-origin: 100% 0;
			@include inline-block;
			> div {
				float: right;
			}
		}
	}
	@media screen and (max-width: 767px) {
		#RecaptchaField2 {
			transform: scale(0.95);
		}
	}
}
/* page contacts */

/* modal */
.modal {
	.modal-dialog {
		max-width: 400px;
		width: auto;
	}
	.modal-content {
		border-radius: 0px;
		h3 {
			margin: 0;
		}
		.close {
			position: absolute;
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			opacity: 1;
			right: 10px;
			top: 17px;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		form {
			display: block;
			margin-top: 15px;
			padding-bottom: 120px;
			input[type="text"], input[type="text"], textarea {
				width: 100%;
				padding: 8px 15px;
				border: 1px solid #b5b5b5;
				margin-bottom: 15px;
			}
			.btn {
				float: right;
				margin-top: 10px;
			}
			#RecaptchaField1 {
				width: 100%;
				display: block;
				margin-bottom: 10px;
				> div {
					float: right;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.modal-content h3 {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 768px) {
		.modal-dialog {
			max-width: 100%;
		}
		.modal-content {
			h3 {
				font-size: 18px;
			}
			form {
				padding-bottom: 40px;
				#RecaptchaField1 {
					display: inline-block;
					transform-origin: 100% 0;
				}
				.btn {
					margin-top: 0px;
				}
			}
		}
	}
}
/* modal */

.whatsupp {
    display: block;
    width: 40px;
    height: 40px;
    background-color: #4dc247;
    font-size: 28px;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    position: fixed;
    right: 18px;
    bottom: 88px;
    padding-top: 1px;
    z-index: 1;
    box-sizing: border-box;
    &:focus {
        color: #fff;
    }
    &:hover {
        color: #fff;
        box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
    }
    @media screen and (max-width: 1170px) {
        bottom: 15px;
    }
    @media screen and (max-width: 991px) {
        width: 35px;
        height: 35px;
        font-size: 24px;
        right: 1px;
    }
}

/* page content */