/* footer */
footer {
	background-color: #15193d;
	padding-top: 25px;
	font-size: 16px;
	color: #cbccd0;
	h6 {
		color: #fff;
	}
	ul {
		@include ul-default;
		li {
			margin-bottom: 5px;
			a {
				display: block;
				color: #cbccd0;
				font-size: 16px;
				&:focus {
					color: #cbccd0;
				}
				&:hover {
					text-decoration: none;
				}
			}
			&.active a {
				color: #d9a100;
			}
		}
	}
	.footer__col {
		min-height: 200px;
		&.col--logo {
			.footer__col__logo {
				display: inline-block;
				margin-top: 70px;
			}
		}
		&.col--contacts {
			padding-left: 50px;
			border-left: 1px solid #8a8c9e;
			.footer__col__left {
				width: 80px;
				@include inline-block;
			}
			.footer__col__right {
				width: calc(100% - 85px);
				@include inline-block;
			}
		}
		.socials {
			margin-top: 25px;
			li {
				margin: 0px 15px 0 0;
				@include inline-block;
				a {
					display: block;
					font-size: 20px;
					color: #fff;
					&:hover {
						color: #d9a100;
					}
				}
			}
		}
	}
	.container-fluid {
		background-color: #fff;
		color: #15193d;
		padding: 10px 0px;
		margin-top: 25px;
		p {
			font-size: 16px;
			margin: 0;
			@include inline-block;
			&.artmedia {
				width: 255px;
				padding-right: 125px;
				float: right;
				position: relative;
				a {
					position: absolute;
					top: -3px;
					right: 0;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.footer__col {
			&.col--contacts {
				padding-left: 0;
				border: none;
			}
		}
	}
	@media screen and (max-width: 767px) {
		font-size: 14px;
		ul li a {
			font-size: 14px;
		}
		.footer__col {
			min-height: 1px;
			margin-bottom: 15px;
			&.col--logo {
				.footer__col__logo {
					margin-top: 0px;
				}
			}
		}
		.container-fluid {
			margin-top: 0px;
			p {
				font-size: 14px;
				&.artmedia {
					width: 240px;
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		.container-fluid {
			p {
				display: block;
				text-align: center;
				&.artmedia {
					text-align: left;
					float: none;
					margin: 5px auto;
				}
			}
		}
	}
}
/* footer */